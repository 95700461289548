import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

import Image from "@components/Image";
import { IAuthor } from "@types";

// this is customized
const Bio: React.FC<IAuthor> = ({ author }) => {
  return (
    <BioContainer>
      <BioAvatar
        as={author.authorsPage ? Link : "div"}
        to={author.slug}
        data-a11y="false"
        aria-label="Author's bio"
      >
        <BioAvatarInner>
          <RoundedImage src={author.avatar.medium} />
        </BioAvatarInner>
      </BioAvatar>
      {/* added below */}
      <BioTexts>
        <BioName>Keiko</BioName>
        <BioText>
          Creating something by React.
          <br />
          <BioLink to="/introduction/">details</BioLink>
        </BioText>
      </BioTexts>
      {/* <BioText dangerouslySetInnerHTML={{ __html: author.bio }} /> */}
    </BioContainer>
  );
};

export default Bio;

const BioContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: -10px;
`;

const BioAvatar = styled.div`
  display: block;
  position: relative;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  margin-right: 16px;
  margin: 10px 26px 10px 10px;

  &::after {
    content: "";
    position: absolute;
    left: -5px;
    top: -5px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  &[data-a11y="true"]:focus::after {
    content: "";
    position: absolute;
    left: -5px;
    top: -5px;
    width: 50px;
    height: 50px;
  }
`;

const RoundedImage = styled(Image)`
  border-radius: 50%;
`;

const BioAvatarInner = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  margin-right: 16px;
  overflow: hidden;
`;

const BioTexts = styled.div`
  max-width: 430px;
  font-size: 14px;
`;

const BioName = styled.p`
  font-weight: bold;
`;

const BioText = styled.p`
  line-height: 1.45;
  color: ${(p) => p.theme.colors.grey};
`;

const BioLink = styled(Link)`
  color: ${(p) => p.theme.colors.accent};

  &:visited {
    color: ${(p) => p.theme.colors.accent};
    opacity: 0.85;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;
